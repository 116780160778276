<template>
    <div class="body">
        <el-container style="height: 95%;">
            <el-header height="10vh">
                <div class="head">
                    <div class="select">
                        <div class="input">
                            <span class="text">EdgeId:</span>
                            <el-input v-model="edgeId"></el-input>
                        </div>
                        <button class="submit" @click="selectData">查询</button>
                        <button class="submit" @click="exportData">导出</button>
                    </div>

                    <el-input class="inputSelect" v-model="findText" placeholder="输入关键字进行筛选"
                        @input="find(findText)"></el-input>
                </div>

            </el-header>
            <el-main>
                <div class="table">
                    <el-table :data="showData" height="100%" style="width: 50%" v-loading="loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" :header-cell-style="{ 'text-align': 'center' }"
                        :cell-style="{ textAlign: 'left' }">
                        <el-table-column prop="_field" width="450px" label="字段名">
                        </el-table-column>
                        <el-table-column prop="value" label="值">
                        </el-table-column>
                        <el-table-column prop="time" label="时间">
                        </el-table-column>
                    </el-table>

                </div>
            </el-main>
        </el-container>

        <el-dialog :visible.sync="downPop" :close-on-click-modal="false" :close-on-press-escape="true"
            :show-close="true" width="20%">
            <div class="downloadText">{{ downloadText }}</div>
            <div style="text-align: center">
                <el-progress type="circle" :percentage="percentage"></el-progress>
            </div>

        </el-dialog>
    </div>

</template>

<script>
import { getEdgeData } from '../../api/analysis';
import axios from 'axios'
export default {
    data() {
        return {
            url: "http://43.143.218.144:9084/",
            downloadUrl: "http://43.143.218.144:9084/dataList/exportDataList",
            edgeId: "",
            tableData: [],
            showData: [],
            loading: false,
            total: 0,
            downPop: false,
            downloadText: "正在下载，请稍后",
            percentage: 0,
            findText: "",
            selectTime: false,
            value1: []
        };
    },
    methods: {
        find(keyWord) {
            this.showData = []
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i]["_field"].indexOf(keyWord) >= 0) {
                    this.showData.push(this.tableData[i])
                }
            }
        },
        selectData() {
            if (this.edgeId == "") {
                this.$message.info("请输入Edge后查询")
                return
            }
            axios.get(this.url + '/dataList/dataList', {
                params: {
                    edgeId: this.edgeId
                }
            }).then((res) => {
                if (res.data.code == "0") {
                    this.tableData = res["data"]["data"]
                    this.showData = this.tableData
                }
                else {
                    this.$message.info(res.data.msg)
                }

            })


        },
        formatDate(date) {
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            var year = date.getFullYear();
            var month = padZero(date.getMonth() + 1); // getMonth() 返回的月份是从 0 开始的
            var day = padZero(date.getDate());
            var hours = padZero(date.getHours());
            var minutes = padZero(date.getMinutes());
            var seconds = padZero(date.getSeconds());

            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        },
        exportData() {
            if (this.edgeId == "") {
                this.$message.info("请输入Edge后查询")
                return
            }
            this.downloadText = "正在下载，请稍后"
            const oneMonthkInMs = 28 * 24 * 60 * 60 * 1000;
            let data = new Date()
            let endTime = this.formatDate(data);
            data = new Date(new Date().getTime() - oneMonthkInMs);
            let startTime = this.formatDate(data);

            this.downPop = true;
            let param = {
                edgeId: this.edgeId,
                startTime: startTime,
                endTime: endTime
            }
            this.percentage = 0;
            const instance = this.initInstance();
            instance({
                method: "get",
                withCredentials: false,
                url: this.downloadUrl,
                params: param,
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
                .then((res) => {
                    const content = res.data;
                    const blob = new Blob([content]);
                    const fileName = "近四周数据.xls"; //替换文件名
                    if ("download" in document.createElement("a")) {
                        // 非IE下载
                        const elink = document.createElement("a");
                        elink.download = fileName;
                        elink.style.display = "none";
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        setTimeout(function () {
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        }, 100);
                    } else {
                        // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                })
                .catch((error) => {
                    this.downPop = false;
                    this.$message.warning("文件下载服务异常")
                    console.log(error)
                });
        },
        initInstance() {
            var _this = this;
            //取消时的资源标记
            this.source = axios.CancelToken.source();
            const instance = axios.create({
                //axios 这个对象要提前导入 或者替换为你们全局定义的
                onDownloadProgress: function (ProgressEvent) {
                    console.log(ProgressEvent);
                    const load = ProgressEvent.loaded;
                    const total = ProgressEvent.total;
                    const progress = (load / total) * 100;

                    console.log(load, total, progress)
                    //一开始已经在计算了 这里要超过先前的计算才能继续往下
                    if (progress > _this.percentage) {
                        _this.percentage = Math.floor(progress);
                    }
                    if (progress == 100) {
                        //下载完成
                        _this.downloadText = "下载完成，按esc退出"
                        _this.downloadButton = true
                        // _this.downPop = false;
                    }
                },
                cancelToken: _this.source.token, //声明一个取消请求token
            });
            return instance;
        },
    }
}
</script>

<style scoped>
.body {
    background: url(../../assets/images/img_02.png) no-repeat center;
    background-size: 100% 100%;
    height: 98vh;
    width: 98vw;
    margin: 1vh 1vw;
    font-size: 18px;
    color: white;
}

.head {
    margin-top: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.select {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
}

.inputSelect {
    width: 20%;
}

.input {
    display: flex;
    flex-direction: row;
    width: 60%;

}

.input .text {
    font-size: 16px;
    line-height: 40px;
    margin-right: 10px;
}

.table {
    height: 98%;
}

.submit {
    font-size: 15px;
    min-width: 95px;
    height: 40px;
    border: none;
    cursor: pointer;
    margin-right: 30px;
    padding: 0 20px;
    background: url(../../assets/images/equip_02.png) no-repeat center;
    background-size: 100% 100%;
}

.submit:hover {
    opacity: 0.85;
}

.downloadText {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.popWindow {
    background: url(../../assets/images/pop_01.png) no-repeat center;
    background-size: 100% 100%;
    padding: 0 15px;
    height: 600px;
}

.pickTime {
    width: 800px;
    height: 600px;
    background-color: aliceblue;
}
</style>