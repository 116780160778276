import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";

// 能源分类列表
export function energyList(params) {
  return http.post(`${resquest}/nengYuanFenXi/001-nengYuanFenXi`, params);
}

// 导出
export function exportExcel(params) {
  return http.post(`${resquest}/nengYuanFenXi/002-nengYuanFenXiReport`, params);
}

//edge最新数据查询

export function getEdgeData(params) {
    return http.get(`/dataList/dataList`, params);
  }